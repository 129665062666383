<template>
  <div class="clouds-stats-item">
    <div class="clouds-stats-item__header">
      <div v-if="$slots.title || title" class="clouds-stats-item__title standart-title">
        <slot name="title">
          {{ title }}
        </slot>
      </div>
      <div class="clouds-stats-item__legend">
        <div v-for="(item, i) in legend" :key="i" class="clouds-stats-item__legend-item">
          <div
            class="clouds-stats-item__legend-item-mark"
            :style="{ backgroundColor: item.color }"
          />
          <div class="clouds-stats-item__legend-item-label small-text">
            {{ item.label }}
          </div>
        </div>
      </div>
    </div>
    <main-card size="small" class="clouds-stats-item__card">
      <base-chart-stack
        :data="data"
        :theme="theme"
        :options="options"
        :show-points="showPoints"
        class="clouds-stats-item__chart"
        :class="{ ['clouds-stats-item__chart--deprecated']: deprecated }"
      />
      <div v-if="deprecated" class="clouds-stats-item__deprecated-overlay medium-text">
        {{ $t('nodata') }}
      </div>
    </main-card>
    <main-card v-if="!deprecated">
      <div>
        {{
          $t('title', {
            title: title,
          })
        }}
        <strong>{{
          $n(
            datasets[0].expense.reduce((a, b) => a + b),
            'currency',
            this.$i18n.locale
          )
        }}</strong>
      </div>
      <div>
        {{ $t('sum') }}

        <strong>{{ $n(datasets[0].data.reduce((a, b) => a + b)) }}</strong>
        {{ datasets[0].label.split(',')[1] }}
      </div>
    </main-card>
  </div>
</template>

<script>
import BaseChartStack from '@/components/BaseChart/BaseChartStack';
import MainCard from '@/components/MainCard/MainCard';
import colors from '@/components/BaseChart/colors';
import { kFormatter } from '@/utils/parsers/sizeParser';
import { parseDateRu } from '@/utils/parsers/dateParser';
export default {
  name: 'StackStatsItem',
  components: { BaseChartStack, MainCard },
  props: {
    title: {
      type: String,
      default: '',
    },
    labels: {
      type: Array,
      required: true,
    },
    datasets: {
      type: Array,
      default: () => [],
      // required: true,
    },
    units: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
      validator: val => !val || ['%', 'bytes'].includes(val),
    },
    deprecated: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colors: colors,
      options: {
        scales: {
          xAxes: [
            {
              title: {
                display: true,
                text: 'Date',
              },
              ticks: { display: true },
              gridLines: {
                display: true,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                min: 0,
                max: this.type === '%' ? 100 : undefined,
                callback: value => {
                  if (this.type || this.units) return this.getFormattedValue(value, this.units);
                  else return value;
                },
              },
            },
          ],
        },

        legend: { display: false },
        tooltips: {
          mode: 'index',
          callbacks: {
            label: (tooltipItem, data) => {
              let label = data.datasets[tooltipItem.datasetIndex].label || '';
              let value = tooltipItem.value;

              return label + ':   ' + value;
            },
            footer: (tooltipItem, data) => {
              const expense = data.datasets[0].expense[tooltipItem[0].index];
              const summary = expense
                ? `Стоимость: ${this.$n(parseFloat(expense), 'currency')}`
                : 'Стоимость:   0 ₽';
              return summary || null;
            },
          },
        },
        // plugins: {
        //   tooltip: {
        //     footerSpacing: 6,
        //     footerMarginTop: 12,
        //   },
        // },
      },
      formatters: {
        bytes: this.getFormattedBytesValue,
      },
    };
  },
  computed: {
    theme() {
      return this.$store.state.moduleApp.theme;
    },
    footer() {
      return '-----------';
    },
    data() {
      const colorMap = Object.keys(this.colors);
      const datasets = this.datasets.map((item, idx) => {
        return {
          ...item,
          backgroundColor: 'gradient',
          borderColor: colorMap[idx],
        };
      });
      return {
        // labels: this.labels,
        labels: datasets[0].date,
        datasets,
      };
    },
    showPoints() {
      // return this.data.labels.length == 1;
      return this.data.labels.length >= 1;
    },
    legend() {
      return this.data.datasets.map(item => {
        return {
          label: item.label,
          color: this.colors[item.borderColor],
        };
      });
    },
  },
  methods: {
    getFormattedValue(value, unit) {
      if (this.formatters[this.type]) return this.formatters[this.type](value, unit);
      else return value + unit;
    },
    getFormattedBytesValue(value, unit) {
      return kFormatter(value, 1024, unit);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "nodata": "Нет данных",
    "title": "Расход за выбранный период по ресурсу \"%{title}\" - ",
    "sum": "Расход ресурсов за выбранный период -"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.clouds-stats-item {
  &__header {
    flexy(space-between, center);
    margin-bottom: 0.75rem;
  }
  &__title {
    margin: auto auto auto 0;
  }
  &__legend {
    margin: auto 0 auto 1rem;
    flexy(flex-end, center);

    &-item {
      flexy(flex-start, center);

      & + & {
        margin-left: 1.5rem;
      }

      &-mark {
        circle(0.5rem, 1px);
        margin-right: 0.25rem;
      }
    }
  }
  &__chart {
    transition: filter 0.3s;
    &--deprecated {
      filter: blur(5px);
    }
  }
  &__deprecated-overlay {
    absolute(0px, 0px);
    size(100%);
    flexy(center, center);
  }
}
</style>
