<template>
  <div class="stack-detail-history">
    <transition name="fade" mode="out-in">
      <page-block :title="$t('title')" class="vps-detail-view__block">
        <stack-stats v-if="tariff" :tariff="tariff" />
        <stack-stats v-else :id="currentId" />
      </page-block>
    </transition>
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable.vue';
import BaseTableRow from '@/components/Table/BaseTableRow.vue';
import { StackTariff } from '@/models/BillMgr/StackTariff';
import { sortFuncsByOrder } from '@/utils/sortFunctions';
import StackStats from '@/layouts/Stack/components/StackStats';
import { format } from 'date-fns';
export default {
  name: 'Statistic',
  components: { StackStats },
  props: {
    tariff: {
      type: Object,
      // required: true,
      default: () => {},
      validator: obj => obj instanceof StackTariff,
    },
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      sort: {
        prop: 'changedate',
        order: 'desc',
      },
    };
  },
  computed: {
    currentId() {
      return this.$attrs.id || this.$store.getters['moduleStack/GET_CURRENT'] || this.id;
    },
  },
  watch: {},
  created() {},
  methods: {},
};
</script>

<i18n>
{
  "ru": {
    "desc": "Что произошло",
    "title":"Статистика",
    "date": "Когда",
    "user": "Инициатор"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
.stack-detail-history {
  margin-top: 1.5rem;

  +breakpoint(sm-and-up) {
    margin-top: 2.5rem;
  }
}
</style>
