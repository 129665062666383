<template>
  <div class="base-chart">
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
// https://www.chartjs.org/docs/latest/
import Chart from 'chart.js';
import colors from './colors';
import { merge } from 'lodash';
const defaultFontFamily =
  'Graphik, Roboto, --apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Verdana, sans-serif';
export default {
  name: 'BaseChartStack',
  props: {
    type: {
      type: String,
      default: 'line',
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: String,
      default: 'light',
      validator: val => ['dark', 'light'].includes(val),
    },
    showPoints: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ctx: null,
      chart: null,
      themes: {
        dark: {
          textColor: '#ffffff',
          scaleColor: '#878B99',
          tooltipBg: '#002A66',
        },
        light: {
          textColor: '#1D2126',
          scaleColor: '#DFE1EB',
          tooltipBg: '#002A66',
        },
      },
      borderWidth: 2,
      colors: colors,
      defaultOptions: {
        general: {
          tooltips: {
            titleFontFamily: defaultFontFamily,
            bodyFontFamily: defaultFontFamily,
            footerFontFamily: defaultFontFamily,
            titleFontSize: 12,
            footerAlign: 'right',
            titleAlign: 'center',
            bodyFontSize: 12,
            intersect: false,
            footerFontSize: 12,
            titleMarginBottom: 12,
            footerMarginTop: 12,
            cornerRadius: 5,
            callbacks: {
              labelColor: (tooltipItem, chart) => {
                return {
                  backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor,
                };
              },
            },
          },
        },
        line: {
          scales: {
            xAxes: [{ gridLines: { borderDash: [1, 1] } }],
            yAxes: [{ gridLines: { borderDash: [1, 1] } }],
          },
        },
      },
      datasetDefaultOptions: {
        line: {
          pointBorderWidth: 0,
          pointBackgroundColor: 'rgba(0,0,0,0)',
          pointBorderColor: 'rgba(0,0,0,0)',
          pointHoverBorderWidth: 2,
          pointHoverBorderColor: '#ffffff',
          pointHitRadius: 5,
        },
      },
    };
  },
  computed: {
    labels() {
      if (!this.data.labels) return [];
      return this.data.labels;
    },
    datasets() {
      if (!this.data.datasets) return [];
      return this.data.datasets.map(item => {
        let obj = { borderWidth: this.borderWidth, ...item };
        const baseColor = Object.keys(this.colors).includes(obj.borderColor)
          ? this.colors[obj.borderColor]
          : obj.borderColor;
        obj.borderColor = baseColor;
        obj.pointHoverBackgroundColor = baseColor;
        if (obj.backgroundColor === 'gradient') {
          obj.backgroundColor = this.makeGradient(baseColor);
          //obj.tooltips = { labelColor: { backgroundColor: baseColor } };
        }
        if (this.datasetDefaultOptions[this.type]) {
          merge(obj, this.datasetDefaultOptions[this.type]);
          if (this.showPoints) obj.pointBackgroundColor = baseColor;
        }
        return obj;
      });
    },
    colorOptions() {
      return {
        tooltips: {
          backgroundColor: this.themes[this.theme].tooltipBg,
        },
        scales: {
          xAxes: [
            {
              gridLines: { color: this.themes[this.theme].scaleColor },
              ticks: { fontColor: this.themes[this.theme].textColor },
            },
          ],
          yAxes: [
            {
              gridLines: { color: this.themes[this.theme].scaleColor },
              ticks: { fontColor: this.themes[this.theme].textColor },
            },
          ],
        },
      };
    },
    finalOptions() {
      let obj = { ...this.defaultOptions.general };
      if (this.defaultOptions[this.type]) merge(obj, this.defaultOptions[this.type]);
      return merge(obj, this.colorOptions, this.options);
    },
  },
  watch: {
    labels: function (value) {
      this.update('labels', value, this.chart.data);
    },
    datasets: function (value) {
      this.update('datasets', value, this.chart.data);
    },
    options: {
      deep: true,
      handler: function (value) {
        this.update('options', value);
      },
    },
    theme: function () {
      this.updateTheme();
    },
  },
  mounted() {
    if (this.$refs.canvas) {
      this.init();
    }
  },
  beforeDestroy() {
    this.destroy();
  },
  methods: {
    init() {
      this.ctx = this.$refs.canvas.getContext('2d');
      this.chart = new Chart(this.ctx, {
        type: this.type,
        data: { labels: this.labels, datasets: this.datasets },
        options: this.finalOptions,
      });
      this.$emit('init', this.chart);
    },
    update(prop, value, path = this.chart) {
      path[prop] = value;
      this.chart.update();
      this.$emit('update', this.chart);
    },
    updateTheme() {
      this.chart.options = { ...this.finalOptions };
      this.chart.update();
    },
    destroy() {
      this.chart.destroy();
      this.chart = null;
      this.ctx = null;
      this.$emit('destroy');
    },
    makeGradient(color, ctx = this.ctx, coords = [0, 0, 0, 260]) {
      if (ctx) {
        let gradient = ctx.createLinearGradient(...coords);
        gradient.addColorStop(0, `${color}01`);
        gradient.addColorStop(0.5, `${color}25`);
        return gradient;
      } else return color;
    },
  },
};
</script>
